<template>
  <div>
    <BrandChooseWindow />
    <Header />
    <SliderFour />
    <!-- <div class="rn-column-area rn-section-gap mt--150"> -->
    <v-container>
      <v-row>
        <v-col>
          <UpdateProfile />
          
        </v-col>
        <v-col>
          <ChangePassword />

        </v-col>
      </v-row>
    </v-container>
    <!-- </div> -->
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header/HeaderOnePage";
import Footer from "@/components/footer/FooterTwo";
import ChangePassword from "@/components/profile/ChangePassword";
import UpdateProfile from "@/components/profile/UpdateProfile";
import axios from "axios";
import BrandChooseWindow from "@/components/brand/BrandChooseWindow";
import SliderFour from "@/components/slider/SliderFour";

import { mapMutations, mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";

export default {
  components: {
    Header,
    Footer,
    SliderFour,
    UpdateProfile,
    ChangePassword,
    BrandChooseWindow,
  },

  data: () => ({
    results: [],
    full_name: "",
    name: "",
    mobile: "",
    gender: "",
    email: "",
    checkbox: false,
    sms: false,
    mail: false,
    push: false,
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    rules: {
      required: (value) => !!value || "Το πεδίο είναι υποχρεωτικό.",
      emailRules: (v) =>
        !v || /.+@.+\..+/.test(v) || "Το email δεν είναι έγγυρο.",
    },
  }),
  computed: {
    ...mapGetters(["user"]),
  },
  async created() {
    await this.getUser();
    if (!this.user) {
      this.$router.push("/");
      return;
    }
  },
  methods: {
    ...mapActions(["getUser"]),

  },
};
</script>
<style scoped>
.v-btn--outlined {
  border: thin solid #ffff !important;
}
.v-btn {
  text-transform: none;
}
.v-input {
  max-width: 70%;
}
.container {
    width: 100%;
}
</style>
